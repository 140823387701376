<template>
    <div class="amount flex justify-center align-center">
        <div v-if="showLabel" class="amount-label">{{amountLabel}}</div>
        <div class="amount-input flex align-center">
            <div class="inside clear flex">
                <span @click="decreaseAmount()" class="input-number-decrement">–</span>
                <input class="input-number" type="text" :value="amount">
                <span @click="increaseAmount()" class="input-number-increment">+</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counter',

    props: {
        showLabel: Boolean,
        quantity: Number,
        maxQuantity: Number
    },

    data() {
        return {
            amount: this.quantity,
        }
    },

    computed: {
        amountLabel() {
            return this.$store.getters.amount;
        }
    },

    methods: {
        decreaseAmount() {
            if (!(this.amount <= 0)) {
                this.amount = --this.amount;
                this.updatePrice('decrease');
            }
        },

        increaseAmount() {
            if (!(this.amount >= this.maxQuantity)) {
                this.amount = ++this.amount;
                this.updatePrice('increase');
            }
        },

        updatePrice(action) {
            this.$emit('amountUpdated', { 
                amount: this.amount,
                action: action 
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.amount {    
    &-label {
        font-size: 1.8rem;
        line-height: 3.8rem;
        font-weight: 300;
        margin: 0 2rem 0 1rem;
    }

    &-input {        
        input[disabled] {
            background: $white !important;
        }

        .inside {
            float: right;
            border: 2px solid $grey-light-3;
            border-radius: 100px;
            overflow: hidden;
            margin-bottom: 0;
        }
    } 
}
    
.input-number {
    width: 4rem;
    // padding: 0 1.2rem;
    vertical-align: top;
    text-align: center;
    outline: none;
    
    &,
    &-decrement,
    &-increment {
        border: none;
        height: 3.4rem;
        user-select: none;
        font-size: 1.6rem;
        font-weight: 700;
        user-select: none;
    }
    
    &-decrement,
    &-increment {
        display: inline-block;
        width: 3rem;
        line-height: 3.4rem;
        background: $white;
        color: $offblack;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        font-family: Arial, sans-serif;
    }

    &-decrement:active,
    &-increment:active {
        background: $grey-light-3;
    }    
}
</style>